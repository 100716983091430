<template>
  <div class="c-wrap">
    <sidebar-train
      v-bind="sidebar"
      :incorrectOrder="incorrectOrder"
      :projectInfo="projectInfo"
      :progressTraining="progressTraining"
      @not-prevent="$emit('not-prevent')"
      @restart-prevent="$emit('restart-prevent')"
    />
    <div class="c-body-wrap">
      <header-tabs
        v-bind="headerTabs"
        @tab-click="$emit('tab-click', $event)"
      />
      <transition-page>
        <transition-toggle-contents>
          <loading v-if="pageLoading" />
          <dataset-setting-main
            v-else
            v-bind="body"
            :upload="upload"
            :tabContens="tabContens"
            :projectItems="projectItems"
            :errorDataset="errorDataset"
            :projectItemsLoading="projectItemsLoading"
            :datasetTableLoading="datasetTableLoading"
            :datasetExists="datasetExists"
            :datasourceList="datasourceList"
            :rawSamples="rawSamples"
            :datasetUnstructuredSamples="datasetUnstructuredSamples"
            :datasourceConfig="datasourceConfig"
            :datasourceLoading="datasourceLoading"
            :datasourceItems="datasourceItems"
            :datasourceSqlItems="datasourceSqlItems"
            :sqlTablePreviewData="sqlTablePreviewData"
            :loadingSqlTablePreview="loadingSqlTablePreview"
            :datasourceItemsTableLoading="datasourceItemsTableLoading"
            :datasourceSetComp="datasourceSetComp"
            :datasourceTestRes="datasourceTestRes"
            :settingNextPage="settingNextPage"
            :datasourceTestLoading="datasourceTestLoading"
            :trainingDatasetTooLarge="trainingDatasetTooLarge"
            @show-table="$emit('show-table', $event)"
            @upload-file="$emit('upload-file', $event)"
            @on-file-input="$emit('on-file-input', $event)"
            @test-datasource="$emit('test-datasource', $event)"
            @set-datasource="$emit('set-datasource', $event)"
            @import-dataset="$emit('import-dataset', $event)"
            @select-dataset="$emit('select-dataset', $event)"
            @open-datasource="$emit('open-datasource', $event)"
            @open-datasource-item="$emit('open-datasource-item', $event)"
            @input-create-form="$emit('input-create-form', $event)"
            @reset-status="$emit('reset-status', $event)"
            @page-back="$emit('page-back')"
            @go-manual-dataset-type="$emit('go-manual-dataset-type')"
            @get-sql-list="$emit('get-sql-list', $event)"
            @select-sql-preview-table="
              $emit('select-sql-preview-table', $event)
            "
            @show-too-large-data="$emit('show-too-large-data', $event)"
          />
        </transition-toggle-contents>
      </transition-page>
    </div>
    <hover-menu @hover-menu-click="$emit('hover-menu-click')" />
    <div class="dataset-list-popup">
      <dataset-setting-popup
        v-bind="popup"
        @close-modal="$emit('close-modal', $event)"
        @reave-to-project="$emit('reave-to-project')"
        @force-select-other-account-dataset="
          $emit('force-select-other-account-dataset', $event)
        "
        @not-prevent="$emit('not-prevent')"
      />
    </div>
  </div>
</template>

<script>
import sidebarTrain from '@/components/organisms/sidebar-train.vue'
import hoverMenu from '@/components/organisms/hover-menu.vue'
import headerTabs from '@/components/organisms/header-tabs.vue'
import datasetSettingMain from '@/components/organisms/dataset-setting/dataset-setting-main.vue'
import loading from '@/components/atoms/loading.vue'
import transitionPage from '@/components/molecules/transition-page'
import TransitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import DatasetSettingPopup from '@/components/organisms/dataset-setting/dataset-setting-popup.vue'

export default {
  components: {
    sidebarTrain,
    hoverMenu,
    headerTabs,
    datasetSettingMain,
    loading,
    transitionPage,
    TransitionToggleContents,
    DatasetSettingPopup
  },
  props: {
    body: Object,
    pageLoading: Boolean,
    projectInfo: Object,
    progressTraining: Object,
    tabContens: String,
    upload: Object,
    errorDataset: Object,
    headerTabs: Object,
    sidebar: Object,
    popup: Object,
    projectItems: Object,
    projectItemsLoading: Boolean,
    datasetTableLoading: Boolean,
    datasetExists: Boolean,
    rawSamples: Object,
    datasourceConfig: Object,
    datasetUnstructuredSamples: Object,
    datasourceList: Array,
    datasourceLoading: Boolean,
    datasourceItems: Array,
    datasourceItemsTableLoading: Boolean,
    datasourceSetComp: Boolean,
    datasourceTestRes: Object,
    settingNextPage: Object,
    datasourceTestLoading: Boolean,
    datasourceSqlItems: Array,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean,
    trainingDatasetTooLarge: Boolean,
    incorrectOrder: Boolean
  }
}
</script>

<style scoped lang="scss">
.c-wrap {
  display: flex;
  width: 100%;
  min-width: 960px;
}

.c-body-wrap {
  width: calc(100% - #{adjustVW(240)});
  margin: adjustVH($headerTabHeight) 0 0;
  background-color: $bg-train;
}
</style>
