<template>
  <div class="datasource-set">
    <button class="back" @click="$emit('close-setting')">
      <text-with-icon
        :text="$t('datasetList.popup.addDataset.backToDatasource')"
        iconName="backward"
      />
    </button>
    <div class="block">
      <input-box-validation
        v-model="datasourceInputValue.name"
        :accept="validate.accept"
        :error="validate.error"
        :placeholder="
          $t('datasetList.popup.setDatasource.datasourceNamePlaceholder')
        "
        :title="$t('datasetList.popup.setDatasource.datasourceName')"
        popupView
        isNameValidation
        :nameValidationSuggests="datasourceFormValidate.suggests"
        @text-input="$emit('input', $event)"
      />
    </div>
    <div class="block">
      <div class="title">
        <texts :text="$t('datasetList.popup.setDatasource.dbType')" />
      </div>
      <div class="type-list">
        <button class="type-item" @click="selectDB('mysql')">
          <datasource-type-wrap
            type="mysql"
            :isOn="datasourceInputValue.dbtype === 'mysql'"
          />
        </button>
        <button class="type-item" @click="selectDB('postgresql')">
          <datasource-type-wrap
            type="postgresql"
            :isOn="datasourceInputValue.dbtype === 'postgresql'"
          />
        </button>
        <button class="type-item" @click="selectDB('oracle')">
          <datasource-type-wrap
            type="oracle"
            :isOn="datasourceInputValue.dbtype === 'oracle'"
          />
        </button>
        <button class="type-item" @click="selectDB('bigquery')">
          <datasource-type-wrap
            type="bigquery"
            :isOn="datasourceInputValue.dbtype === 'bigquery'"
          />
        </button>
        <button class="type-item" @click="selectDB('snowflake')">
          <datasource-type-wrap
            type="snowflake"
            :isOn="datasourceInputValue.dbtype === 'snowflake'"
          />
        </button>
      </div>
    </div>
    <transition-toggle-contents>
      <div v-if="checkBigquery" key="bigquery">
        <div class="list">
          <div class="item">
            <input-box
              v-model="datasourceInputValue.GCPProject"
              :title="$t('datasetList.datasourceList.GCPProject')"
              isGray
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.GCPDatasetId"
              :title="$t('datasetList.datasourceList.GCPDatasetId')"
              isGray
            />
          </div>
        </div>
        <div class="list">
          <div class="item-full">
            <input-box-file
              v-model="datasourceInputValue.credential"
              accept="application/json"
              :title="$t('datasetList.datasourceList.credential')"
              :placeholder="
                $t('datasetList.datasourceList.credentialPlaceholder')
              "
              :info="$t('datasetList.datasourceList.credentialInfo')"
            />
          </div>
        </div>
      </div>
      <div v-else-if="checkSnowflake">
        <div class="list">
          <div class="item">
            <input-box
              v-model="datasourceInputValue.database"
              :title="$t('datasetList.datasourceList.dbName')"
              isGray
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.schema"
              :title="$t('datasetList.popup.setDatasource.schema')"
              isGray
            />
          </div>
        </div>
        <div class="list">
          <div class="item">
            <input-box-validation
              v-model="datasourceInputValue.password"
              :title="$t('datasetList.popup.setDatasource.dbPassword')"
              isPass
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.username"
              :title="$t('datasetList.popup.setDatasource.dbUsername')"
              isGray
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.account"
              :title="$t('datasetList.popup.setDatasource.snowflakeAccount')"
              isGray
            />
          </div>
        </div>
      </div>
      <div v-else key="default">
        <div class="list">
          <div class="item">
            <input-box
              v-model="datasourceInputValue.host"
              :title="$t('datasetList.datasourceList.host')"
              :placeholder="
                $t('datasetList.popup.setDatasource.hostPlaceholder')
              "
              isGray
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.port"
              :title="$t('datasetList.datasourceList.port')"
              :placeholder="$t('datasetList.datasourceList.portPlaceholder')"
              isGray
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.database"
              :title="$t('datasetList.datasourceList.dbName')"
              isGray
            />
          </div>
        </div>
        <div class="list">
          <div class="item">
            <input-box-validation
              v-model="datasourceInputValue.password"
              :title="$t('datasetList.popup.setDatasource.dbPassword')"
              isPass
            />
          </div>
          <div class="item">
            <input-box
              v-model="datasourceInputValue.username"
              :title="$t('datasetList.popup.setDatasource.dbUsername')"
              isGray
            />
          </div>
        </div>
      </div>
    </transition-toggle-contents>
    <div class="button-list">
      <div class="button-item">
        <button-main
          :isDisabled="datasourceTestLoading || isTestDisabled"
          :type="datasourceTestLoading || isTestDisabled ? 'disabled' : 'sub'"
          :text="$t('datasetList.popup.setDatasource.connectionTest')"
          @click="testDatasource"
        />
      </div>
      <div class="button-item">
        <button-main
          :text="$t('datasetList.popup.setDatasource.submit')"
          :isDisabled="isDisabled || validate.error"
          type="emphasis"
          @click="createNewDatasource"
        />
      </div>
      <transition name="respons" mode="out-in">
        <div v-if="datasourceTestLoading" key="loading" class="test-loading">
          <loading-icon />
        </div>
        <text-box
          v-else-if="Object.keys(datasourceTestRes).length"
          key="respons"
          :text="datasourceTestRes.text"
          :isError="datasourceTestRes.status === 'error'"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'
import loadingIcon from '@/components/atoms/loading-icon.vue'
import inputBox from '@/components/molecules/input-box'
import inputBoxValidation from '@/components/molecules/input-box-validation'
import inputBoxFile from '@/components/molecules/input-box-file'
import textWithIcon from '@/components/molecules/text-with-icon'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import datasourceTypeWrap from '@/components/atoms/datasource-type-wrap'
import TextBox from '@/components/atoms/text-box.vue'

const defaultPort = { oracle: 1521, postgresql: 5432, mysql: 3306, bigquery: 0 } // bigqueryはportなしになるが、action側でnullを許可していないため、0を入れる

export default {
  components: {
    texts,
    buttonMain,
    loadingIcon,
    inputBox,
    inputBoxValidation,
    inputBoxFile,
    textWithIcon,
    transitionToggleContents,
    datasourceTypeWrap,
    TextBox
  },
  data() {
    return {
      /** 新規登録するデータソースのv-model */
      datasourceInputValue: {
        name: '',
        description: '',
        dbtype: '',
        host: '',
        port: null,
        database: '',
        username: '',
        password: '',
        GCPProject: '',
        GCPDatasetId: '',
        credential: null
      },
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    datasourceFormValidate: Object,
    /** データソースの接続テストで帰ってくるレスポンス */
    datasourceTestRes: Object,
    datasourceTestLoading: Boolean
  },
  computed: {
    checkBigquery() {
      return this.datasourceInputValue.dbtype === 'bigquery'
    },
    checkSnowflake() {
      return this.datasourceInputValue.dbtype === 'snowflake'
    },
    isDisabled() {
      if (this.isTestDisabled) return true
      return !this.datasourceInputValue.name
    },
    isTestDisabled() {
      if (this.checkBigquery) {
        return !(
          this.datasourceInputValue.credential &&
          this.datasourceInputValue.GCPProject &&
          this.datasourceInputValue.GCPDatasetId
        )
      } else if (this.checkSnowflake) {
        return !(
          this.datasourceInputValue.dbtype &&
          this.datasourceInputValue.account &&
          this.datasourceInputValue.database &&
          this.datasourceInputValue.username &&
          this.datasourceInputValue.password
        )
      } else {
        return !(
          this.datasourceInputValue.dbtype &&
          this.datasourceInputValue.host &&
          this.datasourceInputValue.database &&
          this.datasourceInputValue.username &&
          this.datasourceInputValue.password &&
          this.datasourceInputValue.port
        )
      }
    }
  },
  methods: {
    selectDB(DB) {
      this.datasourceInputValue.dbtype = DB
      this.datasourceInputValue.port = defaultPort[DB]
    },
    createNewDatasource() {
      const req = this.datasourceInputValue
      if (req.dbtype === 'bigquery') {
        req.fileName = req.credential.name
      }
      this.$emit('set-datasource', req)
    },
    testDatasource() {
      const req = this.datasourceInputValue
      // テストの場合はクレデンシャルが入っていなくても通す。（エラーにする）
      if (req.dbtype === 'bigquery') {
        req.fileName = req?.credential?.name ?? null
      }
      this.$emit('test-datasource', req)
    }
  },
  watch: {
    datasourceFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    datasourceInputValue: {
      deep: true,
      handler: function (val) {
        this.$emit('input-create-form', {
          type: 'datasource',
          form: val
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.datasource-set {
  width: adjustVW(592) + adjustVW(960) + adjustVW(24);
}
.block {
  width: adjustVW(592);
  margin: 0 0 $space-large;
}
.list {
  display: flex;
  margin: 0 0 $space-large;
}
.item {
  width: adjustVW(288);
  height: 100%;
  margin: 0 $space-medium 0 0;
  &:last-child {
    margin: 0;
  }
  &-full {
    width: adjustVW(288 * 2 + 24);
  }
}
.title {
  margin: 0 0 $space-base;
}
.type {
  &-list {
    display: flex;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(120);
    height: adjustVH(80);
    margin: 0 $space-small 0 0;
    background: $background-sub;
    border-radius: adjustVW(8);
    &:last-child {
      margin: 0;
    }
  }
}
.button {
  &-list {
    display: flex;
  }
  &-item {
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $background;
  opacity: 0.8;
}
.back {
  margin: 0 0 $space-small;
}
.test-loading {
  display: flex;
  align-items: center;
}

.respons-enter-active,
.respons-leave-active {
  transition: transform $transition-base, opacity $transition-base;
}
.respons-enter,
.respons-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
.respons-enter {
  transform: translateX(-$space-small);
}
.respons-leave-to {
  transform: translateX($space-small);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $transition-base;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  will-change: opacity, transform;
}
</style>
