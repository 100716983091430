<template>
  <div class="limit-wrap">
    <texts
      class="limit-text"
      :text="$t('datasetSetting.popup.otherAccountDataset.desc')"
    />
    <button-main
      :text="$t('datasetSetting.popup.otherAccountDataset.forceStart')"
      type="emphasis"
      @click="forceSelectOtherAccountDataset"
    />
    <button-main
      :text="$t('datasetSetting.popup.otherAccountDataset.selectOther')"
      type="sub"
      @click="$emit('close-modal', 'otherAccountDataset')"
    />
  </div>
</template>

<script>
import texts from '@/components/atoms/text'
import buttonMain from '@/components/atoms/button-main'

export default {
  components: {
    texts,
    buttonMain
  },
  props: {
    otherAccountDataset: Object
  },
  methods: {
    forceSelectOtherAccountDataset() {
      this.$emit('force-select-other-account-dataset', this.otherAccountDataset)
    }
  }
}
</script>

<style lang="scss" scoped>
.limit {
  &-wrap {
    min-width: adjustVW(768 - 48);
  }
  &-text {
    margin-bottom: $space-medium;
    white-space: pre-line;
  }
}
</style>
