import { render, staticRenderFns } from "./dataset-setting-datasource-set.vue?vue&type=template&id=79004564&scoped=true&"
import script from "./dataset-setting-datasource-set.vue?vue&type=script&lang=js&"
export * from "./dataset-setting-datasource-set.vue?vue&type=script&lang=js&"
import style0 from "./dataset-setting-datasource-set.vue?vue&type=style&index=0&id=79004564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79004564",
  null
  
)

export default component.exports