<template>
  <div class="datasource">
    <transition-toggle-contents>
      <div v-if="!setDatasource" key="list" class="datasource-import">
        <div class="content">
          <transition-toggle-contents>
            <!-- データソースをローディング中の処理が常にtrueになってる？のでそれを正しくできるようになったらローディングを出す -->
            <div v-if="!showTable && !isEditSql" key="list" class="list">
              <list-item
                :field="datasourceField"
                :items="datasourceList"
                isToggle
                class="list-inner"
                @list-click="
                  $emit('open-datasource', datasourceList[$event.id])
                "
              >
                <template v-slot:toggleContent="{ entry }">
                  <div class="toggle-wrap">
                    <datasource-contents
                      :datasourceInfo="entry"
                      :dataSourceItems="setDatasourceSetting(entry.id)"
                      :dataSourceSqlItems="setDatasourceSqlSetting(entry.id)"
                      :loadingDatasourceItems="datasourceItemsTableLoading"
                      :connectionError="setDatasourceSettingError(entry.id)"
                      hideReset
                      @set-datasource="$emit('set-datasource', $event)"
                      @show-table="openTable($event)"
                      @get-sql-list="$emit('get-sql-list', entry.id)"
                      @show-sql-edit="showSqlEdit($event, entry.id)"
                    />
                  </div>
                </template>
              </list-item>
              <button-main
                class="list-button"
                :text="
                  $t('datasetList.popup.addDataset.datasource.submitDatasource')
                "
                type="sub"
                @click="setDatasource = !setDatasource"
              />
            </div>
            <div v-else key="add-table" class="add">
              <datasource-import-setting
                :datasourceId="showDatasourceId"
                :datasetFormValidate="datasetFormValidate"
                :importLoading="importLoading"
                :datasourceItemsInfo="setDatasourceSelectTable.table"
                :loadingdatasourceItemsInfo="datasourceItemsTableLoading"
                :maxRows="maxRows"
                :isAddTable="showTable"
                :isEditSql="isEditSql"
                :showSqlValue="showSqlValue"
                :dataSourceSqlItems="setDatasourceSqlSetting(showDatasourceId)"
                :importFromDataSourceTableName="setDatasourceSelectTable.name"
                @close-table="closeTable"
                @do-import="$emit('import-dataset', $event)"
                @input-create-form="$emit('input-create-form', $event)"
              />
            </div>
          </transition-toggle-contents>
        </div>
        <div class="table">
          <transition-toggle-contents>
            <dataset-setting-table
              v-if="showTable"
              v-bind="showTableItem"
              :datasetTableLoading="datasourceItemsTableLoading"
              typr="list"
            />
            <datasource-table-column-list
              v-else-if="isEditSql"
              :dataSourceItems="setDatasourceSetting(showDatasourceId)"
              :sqlTablePreviewData="sqlTablePreviewData"
              :datasourceId="showDatasourceId"
              :loadingSqlTablePreview="loadingSqlTablePreview"
              @select-sql-preview-table="
                $emit('select-sql-preview-table', $event)
              "
            />
          </transition-toggle-contents>
        </div>
      </div>
      <div v-else key="set" class="datasource-set">
        <dataset-setting-datasource-set
          :datasourceFormValidate="datasourceFormValidate"
          :datasourceTestRes="datasourceTestRes"
          :datasourceTestLoading="datasourceTestLoading"
          @close-setting="setDatasource = !setDatasource"
          @input-create-form="$emit('input-create-form', $event)"
          @set-datasource="$emit('set-datasource', $event)"
          @test-datasource="$emit('test-datasource', $event)"
        />
      </div>
    </transition-toggle-contents>
  </div>
</template>

<script>
import buttonMain from '@/components/atoms/button-main.vue'
import listItem from '@/components/molecules/list-item'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import datasetSettingTable from './dataset-setting-table'
import datasetSettingDatasourceSet from './dataset-setting-datasource-set'
import datasourceContents from '@/components/organisms/dataset-list/popup/datasource-contents.vue'
import datasourceImportSetting from '@/components/organisms/dataset-list/popup/datasource-import-setting.vue'
import datasourceTableColumnList from '@/components/organisms/dataset-list/popup/datasource-table-column-list'

export default {
  components: {
    buttonMain,
    listItem,
    transitionToggleContents,
    datasetSettingTable,
    datasetSettingDatasourceSet,
    datasourceContents,
    datasourceImportSetting,
    datasourceTableColumnList
  },
  data() {
    return {
      /** ボタンを押下不可にする */
      isDisabled: true,
      /** 開いているデータソースのID */
      showDatasourceId: 0,
      /** 開いているデータソースのテーブルのID */
      showDatasetId: 0,
      /** テーブルが開いているかどうか */
      showTable: false,
      /** SQLの編集中かどうか */
      isEditSql: false,
      /** 開いているSQLのID */
      showSqlValue: null,
      /** データソースの新規追加画面にいるかどうか */
      setDatasource: false,
      /** テーブルを読み込み中かどうか */
      datasetTableLoading: false,
      /** インポートするデータソースのテーブルのv-model */
      tableInputValue: {
        checked: [],
        columns: null,
        datasourceId: null,
        description: '',
        name: '',
        tableId: null
      },
      /** カラムを制限するかどうか */
      limitsColumn: false,
      /** 最大列数 */
      maxRows: 0,
      validate: {
        accept: null,
        error: null
      }
    }
  },
  props: {
    /** データソース一覧 */
    datasourceList: Array,
    /** データソース一覧を読み込み中かどうか */
    datasourceLoading: Boolean,
    /** 開いたデータソースのテーブル */
    datasourceItems: Array,
    datasourceSqlItems: Array,
    /** データソースのテーブルの詳細のローディング */
    datasourceItemsTableLoading: Boolean,
    /** 新規登録するデータソースの処理が完了したかどうか */
    datasourceSetComp: Boolean,
    /** データソースの接続テストのレスポンス */
    datasourceTestRes: Object,
    datasetFormValidate: Object,
    datasourceFormValidate: Object,
    /** SQLの編集中のテーブルのプレビュー */
    sqlTablePreviewData: Object,
    /** SQLの編集中のテーブルデータの読み込み中かどうか */
    loadingSqlTablePreview: Boolean,
    datasourceTestLoading: Boolean,
    importLoading: Boolean
  },
  computed: {
    datasourceField() {
      return [
        {
          key: 'name',
          label: this.$t(
            'datasetList.popup.addDataset.datasource.datasourceName'
          ),
          thClass: 'name',
          sortable: false
        }
      ]
    },
    datasourceItemField() {
      return [
        {
          key: 'name',
          label: this.$t('datasetList.popup.addDataset.datasource.tableName'),
          thClass: 'name',
          sortable: false
        },
        {
          key: 'n_rows',
          label: this.$t('datasetList.popup.addDataset.datasource.nData'),
          thClass: 'default',
          sortable: false
        }
      ]
    },
    showTableItem() {
      let table = {}
      if (
        this.datasourceItems == null ||
        this.datasourceItems.length === 0 ||
        this.isEditSql
      )
        return table
      const checkDatasource = this.datasourceItems.find(
        (x) => x.id === this.showDatasourceId
      )
      if (!this.datasourceItemsTableLoading && checkDatasource) {
        table = {
          columns: Object.keys(
            checkDatasource.items[this.showDatasetId]?.table.columns
          ),
          table: checkDatasource.items[this.showDatasetId]?.table.data,
          type: 'list'
        }
      }
      return table
    },
    targetDatasource() {
      if (this.datasourceItems == null || this.datasourceItems.length === 0)
        return null
      return this.datasourceItems.find((item) => {
        return item.id === this.showDatasourceId
      })
    },
    setDatasourceSelectTable() {
      if (
        this.datasourceItems == null ||
        this.datasourceItems.length === 0 ||
        this.showDatasetId == null ||
        this.targetDatasource == null
      )
        return null
      return this.targetDatasource.items[this.showDatasetId]
    }
  },
  methods: {
    openTable({ datasourceId, table, tableId }) {
      this.showTable = true

      this.showDatasourceId = datasourceId
      this.showDatasetId = tableId
      this.maxRows = table.n_rows

      const payload = {
        id: datasourceId,
        table: table.name
      }
      this.$emit('open-datasource-item', payload)

      if (
        this.tableInputValue.datasourceId !== datasourceId ||
        this.tableInputValue.tableId !== tableId
      ) {
        this.initTableInfo({ datasourceId, tableId })
      }
    },
    initTableInfo: function ({ datasourceId, tableId }) {
      this.tableInputValue.datasourceId = datasourceId
      this.tableInputValue.tableId = tableId

      const tableInfo = {
        checked: [],
        columns: null,
        description: '',
        name: ''
      }

      const tableInfoKeys = Object.keys(tableInfo)
      tableInfoKeys.forEach((key) => {
        this.$delete(this.tableInputValue, key)
        this.$set(this.tableInputValue, key, tableInfo[key])
      })

      this.limitsColumn = false
    },
    setTime() {
      this.datasetTableLoading = false
    },
    closeTable() {
      this.showTable = false
      this.showDatasourceId = 0
      this.showDatasetId = 0
      this.showSqlValue = null
      this.isEditSql = false
      this.$emit('reset-status')
    },
    setDatasourceSetting(id) {
      if (this.datasourceItems == null || this.datasourceItems.length === 0)
        return null
      const check = this.datasourceItems.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.items
    },
    setDatasourceSqlSetting(id) {
      if (
        this.datasourceSqlItems == null ||
        this.datasourceSqlItems.length === 0
      )
        return null
      const check = this.datasourceSqlItems.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.items
    },
    setDatasourceSettingError(id) {
      if (this.datasourceItems == null || this.datasourceItems.length === 0)
        return null

      const check = this.datasourceItems.find((item) => {
        return item.id === id
      })
      if (!check) return null
      return check.error
    },
    showSqlEdit(id, datasourceId) {
      this.showSqlValue = id ?? null
      this.isEditSql = true
      this.showDatasourceId = datasourceId
      this.$emit('show-sql-edit', datasourceId)
    }
  },
  watch: {
    datasetFormValidate: {
      deep: true,
      handler: function (status) {
        const { duplicate } = status

        this.validate.accept = duplicate !== undefined ? duplicate : null
        this.validate.error = duplicate !== undefined ? !duplicate : null
      }
    },
    datasourceSetComp(newVal) {
      this.setDatasource = !newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.datasource {
  height: 100%;
  &-import {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr adjustVW(966);
    grid-template-rows: 100%;
    grid-column-gap: $space-medium;
    height: 100%;
  }
}
.content {
  height: 100%;
}
.list {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  padding: 0 $space-base 0 0;
  @include scrollbar;
  &-button {
    width: adjustVW(280);
  }
  &-inner {
    margin: 0 0 $space-small;
  }
}
.toggle {
  &-wrap {
    margin: 0 0 $space-sub;
  }
  &-info {
    padding: 0 0 $space-medium;
    margin: 0 0 $space-medium;
    border-bottom: $border-sub;
  }
  &-block {
    display: flex;
    width: 100%;
    margin: 0 0 $space-small;
    &:last-child {
      margin: 0;
    }
  }
  &-inner {
    margin: 0 $space-medium 0 0;
    &:last-child {
      margin: 0;
    }
  }
  &-text {
    margin: 0 0 $space-sub;
    &:last-child {
      margin: 0;
    }
  }
  &-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(120);
    background: $background-sub;
  }
  &-host {
    overflow: hidden;
  }
}

.add {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-row-gap: $space-small;
  height: 100%;
}

.input {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  height: 100%;
  margin: 0 0 $space-medium;
  &-limit {
    display: flex;
    align-items: center;
    padding: $space-base 0;
    margin: 0 0 $space-small;
    border-top: $border-sub;
    border-bottom: $border-sub;
    &-input {
      display: flex;
      align-items: center;
      &-box {
        margin: 0 $space-base 0 0;
      }
    }
  }
}

.table {
  overflow: hidden;
  &-block {
    margin: 0 0 $space-medium;
    &:last-of-type {
      margin: 0;
    }
    &-check {
      overflow: hidden;
      flex-shrink: 1;
    }
  }
  &-back {
    margin: 0 0 $space-small;
  }
  &-check-wrap {
    overflow-y: auto;
    height: 100%;
    max-height: adjustVH(196);
    @include scrollbar;
  }
}
</style>
