<template>
  <div class="select">
    <div class="list">
      <transition-toggle-contents>
        <div v-if="!projectItemsLoading" key="item" class="item">
          <list-item-slot :field="field" :items="sortedItems">
            <template v-slot:name="entry">
              <button class="item-inner" @click="showTable(entry.entry.id)">
                <texts class="item-name" :text="entry.entry.name" isBold />
                <div class="item-desc">
                  <texts
                    :ref="'itemDesc' + entry.listIndex"
                    class="item-desc-inner"
                    :text="entry.entry.description"
                    size="small"
                    noTips
                  />
                  <div
                    v-if="!loadingTips"
                    v-tooltip="entry.entry.tips"
                    class="item-desc-tips"
                  />
                </div>
              </button>
            </template>
          </list-item-slot>
        </div>
        <div v-else key="loading" class="loading">
          <loading />
        </div>
      </transition-toggle-contents>
      <button-large
        class="button"
        :text="$t('datasetSetting.selectDataset.submit')"
        :isDisabled="disable"
        @click="$emit('select-dataset', selected)"
      />
    </div>
    <div class="table">
      <dataset-setting-table
        :table="checkRawSamples.table"
        :columns="checkRawSamples.tableColumns"
        :type="checkRawSamples.type"
        :datasetTableLoading="datasetTableLoading"
        :trainingDatasetTooLarge="trainingDatasetTooLarge"
        :nLabels="nLabels"
        @show-too-large-data="$emit('show-too-large-data', selected)"
      />
    </div>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading'
import ButtonLarge from '@/components/atoms/button-large'
import listItemSlot from '@/components/molecules/list-item-slot'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents'
import datasetSettingTable from './dataset-setting-table'

export default {
  components: {
    loading,
    ButtonLarge,
    listItemSlot,
    transitionToggleContents,
    datasetSettingTable
  },
  data() {
    return {
      field: [
        {
          id: 0,
          key: 'name',
          label: this.$t('datasetSetting.selectDataset.table.fieldName'),
          width: 554
        }
      ],
      unstructuredField: {
        images: [
          {
            id: 0,
            key: 'name',
            label: this.$t('datasetSetting.selectDataset.field.columnName'),
            width: 176
          },
          {
            id: 1,
            key: 'count',
            label: this.$t('datasetSetting.selectDataset.field.count'),
            width: 120
          },
          {
            id: 1,
            key: 'sample',
            label: this.$t('datasetSetting.selectDataset.field.images'),
            width: 534
          }
        ],
        texts: [
          {
            id: 0,
            key: 'name',
            label: this.$t('datasetSetting.selectDataset.field.columnName'),
            width: 176
          },
          {
            id: 1,
            key: 'count',
            label: this.$t('datasetSetting.selectDataset.field.count'),
            width: 120
          },
          {
            id: 1,
            key: 'sample',
            label: this.$t('datasetSetting.selectDataset.field.texts'),
            width: 534
          }
        ]
      },
      selected: null,
      nLabels: 0,
      loadingTips: false
    }
  },
  props: {
    /** データセット一覧 */
    projectItems: Object,
    /** データセット一覧 ローディング中 */
    projectItemsLoading: Boolean,
    /** データセット一覧 を選択してテーブルをローディング中かどうか */
    datasetTableLoading: Boolean,
    /** データセット一覧 を選択して表示されるテーブル */
    rawSamples: Object,
    /** データセット一覧 を選択した時にエラーが出たらエラー分が入る */
    errorDataset: Object,
    datasetUnstructuredSamples: Object,
    trainingDatasetTooLarge: Boolean
  },
  computed: {
    disable() {
      if (
        this.errorDataset.inPreprocessingOtherDataset ||
        this.datasetTableLoading
      ) {
        return true
      } else {
        return !this.selected || this.errorDataset.flag
      }
    },
    checkRawSamples() {
      if (this.rawSamples == null) return {}
      let type = ''

      if (this.rawSamples?.detail?.nRows > 0) {
        type = 'list'
        return {
          tableColumns: Object.keys(this.rawSamples.detail.dtypes),
          table: this.rawSamples.list,
          type: type
        }
      } else if (
        this.rawSamples?.detail?.nImages > 0 ||
        this.rawSamples?.detail?.nTexts > 0
      ) {
        const items = []
        let nLabelsCount = 0
        for (const [key, value] of Object.entries(
          this.datasetUnstructuredSamples.labels
        )) {
          items.push({
            name: key,
            count: value.count,
            sample: value.datas
          })
          nLabelsCount = nLabelsCount + value.count
        }
        this.nLabelsCount(nLabelsCount)

        if (this.rawSamples?.detail?.nImages > 0) {
          type = 'images'
        } else {
          type = 'texts'
        }

        return {
          tableColumns: this.unstructuredField[type],
          table: items,
          type: type
        }
      }
      return {}
    },
    sortedItems() {
      if (!this.projectItems) return []
      return Object.values(this.projectItems).sort((x, y) =>
        x.updateTime < y.updateTime ? 1 : x.updateTime > y.updateTime ? -1 : 0
      )
    }
  },
  methods: {
    nLabelsCount(count) {
      this.nLabels = count
    },
    showTable: function (id) {
      const selectedDataset = this.sortedItems.find((item) => item.id === id)

      if (this.selected === null || selectedDataset.id !== this.selected.id) {
        this.$emit('show-table', selectedDataset)
        this.selected = selectedDataset
      }
    },
    checkHeight: function () {
      console.log(this.$refs)
    },
    descTooltip(index, text) {
      const checkTarget = this.$refs['itemDesc' + index].$el
      const textHeight = checkTarget.clientHeight
      const lineHeight = checkTarget.scrollHeight
      if (lineHeight > textHeight) {
        return {
          content: text,
          trigger: 'hover',
          delay: { show: 200, hide: 200 }
        }
      } else {
        return null
      }
    }
  },
  mounted() {
    this.loadingTips = true
    this.$nextTick(() => {
      this.sortedItems.forEach((item, index) => {
        item.tips = this.descTooltip(index, item.description)
      })
      this.loadingTips = false
    })
  }
}
</script>

<style lang="scss" scoped>
.select {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr adjustVW(966);
  grid-column-gap: $space-medium;
  height: 100%;
}
.list {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.item {
  overflow-y: scroll;
  flex-shrink: 1;
  height: 100%;
  padding: 0 $space-base 0 0;
  margin: 0 0 $space-medium;
  @include scrollbar;
  &-inner {
    overflow: hidden;
    width: 100%;
    text-align: left;
    &:hover {
      opacity: 1;
    }
  }
  &-name {
    overflow: hidden;
    margin-bottom: $space-text;
  }
  &-desc {
    position: relative;
    width: fit-content;
    max-width: 100%;
    &-inner {
      overflow: hidden;
      display: -webkit-box;
      word-break: break-all;
      white-space: pre-line;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      box-orient: vertical;
    }
    &-tips {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.button {
  flex-shrink: 0;
}
.table {
  overflow: hidden;
}
.loading {
  position: relative;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
