<template>
  <div class="wrapper">
    <div ref="tabWrap" class="tab">
      <tab-list
        :tabs="tabs"
        :activeTabProps="activeTabProps"
        @change-tab="changeTabContents($event)"
      />
      <transition-toggle-contents>
        <text-box
          v-if="checkError"
          ref="errorList"
          key="default"
          class="tab-error-list"
          isError
          :style="{ '--tabPosition': tabPosition + 'px' }"
        >
          <texts
            v-for="message in errorDataset.message"
            :key="message"
            class="tab-error-item"
            :text="message"
            color="caution"
            size="min"
          />
        </text-box>
        <text-box
          v-else-if="datasourceErrorText"
          key="datasource"
          class="tab-error-datasource"
          isError
        >
          <texts
            class="tab-error-item"
            :text="datasourceErrorText"
            color="caution"
            size="min"
          />
        </text-box>
      </transition-toggle-contents>
      <transition-toggle-contents>
        <div v-if="showPlanChangeGuide && !checkError" class="free">
          <texts class="free-message" color="caution" size="min">
            {{ disablePlanTips }}
            <router-link class="free-link" :to="{ name: 'changePlan' }">
              {{ $t('recipeDetail.caution.free.link') }}
            </router-link>
            {{ $t('recipeDetail.caution.free.last') }}
          </texts>
        </div>
      </transition-toggle-contents>
    </div>
    <card-base class="body">
      <transition-toggle-contents>
        <dataset-setting-select
          v-if="tabContens === 'dataset'"
          key="dataset"
          :projectItems="projectItems"
          :projectItemsLoading="projectItemsLoading"
          :datasetTableLoading="datasetTableLoading"
          :errorDataset="errorDataset"
          :rawSamples="rawSamples"
          :datasetUnstructuredSamples="datasetUnstructuredSamples"
          :trainingDatasetTooLarge="trainingDatasetTooLarge"
          @show-table="$emit('show-table', $event)"
          @select-dataset="$emit('select-dataset', $event)"
          @show-too-large-data="$emit('show-too-large-data', $event)"
        />
        <dataset-setting-add-file
          v-else-if="tabContens === 'upload'"
          key="upload"
          :datasetFormValidate="upload.createInfo.datasetFormValidate"
          :uploadLearningDataErrors="upload.uploadLearningDataErrors"
          :resetInputValue="upload.resetInputValue"
          @input-create-form="$emit('input-create-form', $event)"
          @on-file-input="$emit('on-file-input', $event)"
          @upload-file="$emit('upload-file', $event)"
          @go-manual-dataset-type="$emit('go-manual-dataset-type')"
        />
        <dataset-setting-add-datasource
          v-else-if="tabContens === 'import'"
          key="import"
          :datasetFormValidate="upload.createInfo.datasetFormValidate"
          :datasourceFormValidate="upload.createInfo.datasourceFormValidate"
          :datasourceItems="datasourceItems"
          :datasourceSqlItems="datasourceSqlItems"
          :sqlTablePreviewData="sqlTablePreviewData"
          :loadingSqlTablePreview="loadingSqlTablePreview"
          :datasourceItemsTableLoading="datasourceItemsTableLoading"
          :datasourceList="datasourceList"
          :datasourceLoading="datasourceLoading"
          :datasourceSetLoading="datasourceSetLoading"
          :datasourceSetComp="datasourceSetComp"
          :datasourceTestRes="datasourceTestRes"
          :datasourceTestLoading="datasourceTestLoading"
          :importLoading="datasourceConfig.importing"
          @import-dataset="$emit('import-dataset', $event)"
          @input-create-form="$emit('input-create-form', $event)"
          @open-datasource="$emit('open-datasource', $event)"
          @open-datasource-item="$emit('open-datasource-item', $event)"
          @set-datasource="$emit('set-datasource', $event)"
          @test-datasource="$emit('test-datasource', $event)"
          @reset-status="$emit('reset-status')"
          @get-sql-list="$emit('get-sql-list', $event)"
          @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
        />
      </transition-toggle-contents>
      <transition-toggle-contents>
        <wait-setting-dataset
          v-if="upload.uploadLoading"
          v-bind="upload.progress"
          :isLargeCsv="upload.progress.isLargeCsv"
          :uploadLearningDataWarnings="upload.uploadLearningDataWarnings"
        />
        <div v-if="datasourceConfig.importing" class="wait-wrap">
          <loading />
        </div>
        <div v-if="settingNextPage.loadingNextPage" class="wait-wrap">
          <div class="wait-loading">
            <loading />
          </div>
          <div class="wait-text">
            <div class="wait-text-item">
              <texts
                :text="$t('datasetSetting.selectDataset.waitGetDetail')"
                size="small"
                color="gray"
              />
            </div>
            <transition-toggle-contents>
              <div v-if="settingNextPage.fileTooLarge" class="wait-text-item">
                <texts
                  :text="$t('datasetSetting.selectDataset.fileTooLarge')"
                  size="small"
                  color="gray"
                />
              </div>
            </transition-toggle-contents>
          </div>
        </div>
      </transition-toggle-contents>
    </card-base>
  </div>
</template>

<script>
import loading from '@/components/atoms/loading.vue'
import cardBase from '@/components/atoms/card-base.vue'
import texts from '@/components/atoms/text.vue'
import textBox from '@/components/atoms/text-box.vue'
import tabList from '@/components/molecules/tab-list.vue'
import transitionToggleContents from '@/components/molecules/transition-toggle-contents.vue'
import waitSettingDataset from '@/components/organisms/wait-setting-dataset.vue'
import datasetSettingAddDatasource from './dataset-setting-add-datasource.vue'
import datasetSettingAddFile from './dataset-setting-add-file.vue'
import datasetSettingSelect from './dataset-setting-select.vue'
import { checkExpiredEducation } from '@/lib/misc.js'

export default {
  components: {
    loading,
    cardBase,
    texts,
    textBox,
    tabList,
    transitionToggleContents,
    waitSettingDataset,
    datasetSettingSelect,
    datasetSettingAddFile,
    datasetSettingAddDatasource
  },
  data() {
    const checkPlan =
      this.accountInfo?.plan === 'free' ||
      checkExpiredEducation(this.accountInfo)
    return {
      tabContens: this.datasetExists || checkPlan ? 'dataset' : 'upload',
      activeTabProps: this.datasetExists || checkPlan ? 0 : 1,
      tabPosition: 0
    }
  },
  computed: {
    tabs() {
      const plan = this.accountInfo.plan
      const restriction = !this.usageRestriction[plan]

      const tooltip =
        restriction && !this.checkFree && !this.checkEducation
          ? {
              content: this.$t('datasetList.message.usageRestriction'),
              trigger: 'hover',
              delay: { show: 200, hide: 200 },
              autoHide: false
            }
          : {}

      return [
        {
          id: 0,
          name: this.$t('datasetSetting.tabs.dataset'),
          iconName: 'dataset',
          value: 'dataset'
        },
        {
          id: 1,
          name: this.$t('datasetSetting.tabs.file'),
          iconName: 'detail',
          value: 'upload',
          disabled: restriction || this.showPlanChangeGuide,
          tooltip
        },
        {
          id: 2,
          name: this.$t('datasetSetting.tabs.datasource'),
          iconName: 'datasource',
          value: 'import',
          disabled: restriction || this.showPlanChangeGuide,
          tooltip
        }
      ]
    },
    checkError() {
      return (
        (this.errorDataset.flag || this.errorDataset.cautionFlag) &&
        this.errorDataset.message.length > 0
      )
    },
    checkFree() {
      return this.accountInfo.plan === 'free'
    },
    checkEducation() {
      return checkExpiredEducation(this.accountInfo)
    },
    showPlanChangeGuide() {
      return this.checkFree || this.checkEducation
    },
    disablePlanTips() {
      if (this.checkFree) {
        return this.$t('datasetList.message.freePlanCanNotUpload')
      } else if (this.checkEducation) {
        return this.$t('datasetList.message.expiredEducationCanNotUpload')
      } else {
        return ''
      }
    },
    datasourceErrorText() {
      if (this.datasourceConfig.importingError == null) return null
      return this.$t(
        `datasetList.datasourceList.importError.${this.datasourceConfig.importingError}`
      )
    }
  },
  props: {
    accountInfo: Object,
    upload: Object,
    usageRestriction: Object,
    datasourceConfig: Object,
    projectItems: Object,
    errorDataset: Object,
    projectItemsLoading: Boolean,
    datasetTableLoading: Boolean,
    datasetExists: Boolean,
    rawSamples: Object,
    datasetUnstructuredSamples: Object,
    datasourceList: Array,
    datasourceLoading: Boolean,
    datasourceItems: Array,
    datasourceSqlItems: Array,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean,
    datasourceItemsTableLoading: Boolean,
    datasourceSetLoading: Boolean,
    datasourceSetComp: Boolean,
    datasourceTestRes: Object,
    settingNextPage: Object,
    datasourceTestLoading: Boolean,
    trainingDatasetTooLarge: Boolean
  },
  methods: {
    changeTabContents(e) {
      this.tabContens = e
      this.$emit('reset-status')
    }
  },
  watch: {
    checkError(newVal) {
      if (newVal) {
        const offsetTop = this.$refs.tabWrap.offsetTop
        const offsetHeight = this.$refs.tabWrap.offsetHeight
        let targetHeight = 0
        this.$nextTick(() => {
          targetHeight = this.$refs.errorList.$el.offsetHeight
          this.tabPosition = offsetTop - targetHeight + offsetHeight
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: $space-small;
  height: 100%;
  padding: 0 $space-medium $space-medium;
}

.body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: $space-medium;
}

.tab {
  display: flex;
  width: 100%;
  &-error {
    &-list {
      --tabPosition: 0;
      position: absolute;
      top: var(--tabPosition);
      right: $space-medium;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: $space-medium;
      z-index: 1000;
    }
    &-item {
      margin-right: $space-small;
      &:last-of-type {
        margin: 0;
      }
    }
    &-datasource {
      margin-left: auto;
    }
  }
}

.error {
  margin: 0 0 0 adjustVW(30);
}

.wait {
  &-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-row-gap: $space-large;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    z-index: 100;
  }
  &-loading {
    width: auto;
    height: auto;
  }
  &-text {
    text-align: center;
    &-item {
      &:not(:last-of-type) {
        margin-bottom: $space-base;
      }
    }
  }
}

.free {
  display: flex;
  align-items: center;
  margin-left: $space-medium;
  &-message {
    white-space: pre-line;
  }
  &-link {
    color: $text-caution;
    text-decoration: underline;
  }
}
</style>
