<template>
  <popup-array
    :showPopup="showPopup"
    :popupDefs="popupDefs"
    :noClose="showPopup.includes('preventTrainingStatus')"
    @close-modal="$emit('close-modal', $event)"
  >
    <template #reaveToProject>
      <reave-to-project
        @close-modal="$emit('close-modal', $event)"
        @reave-to-project="$emit('reave-to-project')"
      />
    </template>
    <template #otherAccountDataset>
      <other-account-dataset
        :otherAccountDataset="otherAccountDataset"
        @close-modal="$emit('close-modal', $event)"
        @force-select-other-account-dataset="
          $emit('force-select-other-account-dataset', $event)
        "
      />
    </template>
    <template #upperLimitDataSize>
      <upper-limit-data-size
        :sizeInfo="sizeInfo"
        @close-modal="$emit('close-modal', 'upperLimitDataSize')"
      />
    </template>
    <template #preventTrainingStatus>
      <prevent-training-status @not-prevent="$emit('not-prevent')" />
    </template>
  </popup-array>
</template>

<script>
import reaveToProject from '@/components/organisms/reave-to-project'
import otherAccountDataset from './popup/other-account-dataset'
import upperLimitDataSize from '@/components/organisms/dataset-list/popup/upper-limit-data-size.vue'
import preventTrainingStatus from '@/components/organisms/common-popups/prevent-training-status.vue'
import popupArray from '@/components/molecules/popup/popup-array.vue'

export default {
  components: {
    reaveToProject,
    otherAccountDataset,
    upperLimitDataSize,
    preventTrainingStatus,
    popupArray
  },
  props: {
    showPopup: Array,
    otherAccountDataset: Object,
    sizeInfo: Object
  },
  computed: {
    popupDefs() {
      return {
        reaveToProject: {
          // プロジェクト作成に戻る場合
          title: this.$t('common.backToProject.title')
        },
        otherAccountDataset: {
          title: this.$t('datasetSetting.popup.otherAccountDataset.title')
        },
        upperLimitDataSize: {
          title: this.$t('datasetList.popup.upperLimitDataSize.title')
        },
        preventTrainingStatus: {
          title: this.$t('common.popup.preventTrainingStatus.title')
        }
      }
    }
  }
}
</script>
