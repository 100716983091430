<template>
  <div class="main">
    <page-top
      class="top"
      :title="$t('datasetSetting.title')"
      iconName="backward"
      notScroll
      isButton
      size="min"
      isTrain
      @click="$emit('page-back')"
    />
    <dataset-setting-body
      v-bind="mainData"
      :upload="upload"
      :errorDataset="errorDataset"
      :projectItems="projectItems"
      :projectItemsLoading="projectItemsLoading"
      :datasetTableLoading="datasetTableLoading"
      :datasetExists="datasetExists"
      :rawSamples="rawSamples"
      :datasetUnstructuredSamples="datasetUnstructuredSamples"
      :datasourceConfig="datasourceConfig"
      :datasourceList="datasourceList"
      :datasourceLoading="datasourceLoading"
      :datasourceItems="datasourceItems"
      :datasourceSqlItems="datasourceSqlItems"
      :sqlTablePreviewData="sqlTablePreviewData"
      :loadingSqlTablePreview="loadingSqlTablePreview"
      :datasourceItemsTableLoading="datasourceItemsTableLoading"
      :datasourceSetComp="datasourceSetComp"
      :datasourceTestRes="datasourceTestRes"
      :settingNextPage="settingNextPage"
      :datasourceTestLoading="datasourceTestLoading"
      :trainingDatasetTooLarge="trainingDatasetTooLarge"
      @show-table="$emit('show-table', $event)"
      @upload-file="$emit('upload-file', $event)"
      @on-file-input="$emit('on-file-input', $event)"
      @test-datasource="$emit('test-datasource', $event)"
      @set-datasource="$emit('set-datasource', $event)"
      @import-dataset="$emit('import-dataset', $event)"
      @select-dataset="$emit('select-dataset', $event)"
      @open-datasource="$emit('open-datasource', $event)"
      @open-datasource-item="$emit('open-datasource-item', $event)"
      @input-create-form="$emit('input-create-form', $event)"
      @reset-status="$emit('reset-status')"
      @go-manual-dataset-type="$emit('go-manual-dataset-type')"
      @get-sql-list="$emit('get-sql-list', $event)"
      @select-sql-preview-table="$emit('select-sql-preview-table', $event)"
      @show-too-large-data="$emit('show-too-large-data', $event)"
    />
  </div>
</template>

<script>
import pageTop from '@/components/atoms/page-top'
import datasetSettingBody from './dataset-setting-body'
export default {
  components: {
    pageTop,
    datasetSettingBody
  },
  props: {
    mainData: Object,
    upload: Object,
    errorDataset: Object,
    projectItems: Object,
    projectItemsLoading: Boolean,
    datasetTableLoading: Boolean,
    datasetExists: Boolean,
    rawSamples: Object,
    datasetUnstructuredSamples: Object,
    datasourceConfig: Object,
    datasourceList: Array,
    datasourceLoading: Boolean,
    datasourceItems: Array,
    datasourceItemsTableLoading: Boolean,
    datasourceSetComp: Boolean,
    datasourceTestRes: Object,

    settingNextPage: Object,
    datasourceTestLoading: Boolean,
    datasourceSqlItems: Array,
    sqlTablePreviewData: Object,
    loadingSqlTablePreview: Boolean,
    trainingDatasetTooLarge: Boolean
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh - adjustVH($headerTabHeight);
}

.top {
  padding: $space-medium $space-medium $space-small;
}
</style>
