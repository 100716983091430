<!-- 学習中の画面でプロジェクト作成に戻るときに表示するポップアップの中身 -->
<template>
  <div class="reave-wrap">
    <texts
      class="reave-text"
      :text="$t('common.backToProject.description')"
      isShowAll
      size="small"
    />
    <div class="reave-button">
      <button-main
        :text="$t('common.backToProject.button')"
        type="emphasis"
        @click="$emit('reave-to-project')"
      />
      <button-main
        :text="$t('common.cancel')"
        type="sub"
        @click="$emit('close-modal', 'reaveToProject')"
      />
    </div>
  </div>
</template>

<script>
import texts from '../atoms/text.vue'
import buttonMain from '../atoms/button-main.vue'

export default {
  components: {
    texts,
    buttonMain
  }
}
</script>

<style scoped lang="scss">
.reave {
  &-wrap {
    width: adjustVW(720) - $space-medium * 2;
  }
  &-text {
    margin: 0 0 $space-medium;
    white-space: pre-line;
  }
}
</style>
